/**
 * @author       Peter Hutsul <peter@greenpandagames.com>
 * @copyright    2021 GREEN PANDA GAMES
 * @license      {@link https://legal.ubi.com/privacypolicy/en-INTL}
 */

// '<meta name="viewport" content="initial-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover,minimal-ui">',

const DEFAULT_HTML = [
    '<meta charset="utf-8">',
    '<meta http-equiv="Content-Type" content="text/html; charset=utf-8">',
    '<meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no">',
    '<meta name="apple-mobile-web-app-status-bar-style" content="black">',
    '<meta name="apple-mobile-web-app-capable" content="yes">',
    '<meta name="mobile-web-app-capable" content="yes">',
    '<meta name="ad.size" content="width={GOOGLE_ADS_WIDTH},height={GOOGLE_ADS_HEIGHT}">',
    '<style>',
    'body,html{width:100%;height:100%;margin:0;padding:0;overflow:hidden;background-color:#2a2a2a;}',
    'body{-webkit-user-select:none;touch-action:none;user-select:none;}',
    '*{-webkit-tap-highlight-color:transparent;-webkit-touch-callout:none;-webkit-user-select:none;user-select:none;}',
    '#gscalable,#scalable{transform-origin: 0px 0px;z-index:1;pointer-events:none;}#scalable,#gscalable,#creative{position:absolute;top:0px;left:0px;}',
    '</style>',
    '\n'
].join('');

export default DEFAULT_HTML;
